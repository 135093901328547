<template>
  <v-dialog v-model="newsDialog" max-width="800px" ref="div1">
    <v-card tile class="pb-2 pa-5">
      <!-- <pre>{{newsContent}}</pre> -->
      <!-- <p>{{newsComent.created}}</p> -->
      <v-btn absolute fab small error top right class="mt-10 mr-1 elevation-0" @click="closeDialog">
        <!-- <v-icon>clear</v-icon> -->
        X
      </v-btn>
      <v-img v-if="newsContent.mediaurl !== ''" :src="newsContent.mediaurl" contain></v-img>
      <v-card-text class="pt-2">
        <div class="title">
          {{ newsContent.title }}
        </div>
        <div v-html="newsContent.content"></div>
      </v-card-text>  
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data(){
      return {
        newsDialog: false,
        newsContent: {}
      }
    },
    methods: {
      // Open Dialog with News
      openDialog(newsData){
        // this.scrollToTop();
        this.newsDialog = true;
        this.newsContent = newsData;
        this.goto('div1');
      },

      // Close the news dialog
      closeDialog(){
        this.newsContent = {};
        this.newsDialog = false;
      },
      scrollToTop() {
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      },
      goto(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;

        window.scrollTo(0, top);
      }
    },
  }
</script>