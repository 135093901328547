<template>
  <v-app>
    <!-- Workaround, by adding several if conditions first -->
    <NavbarEvisit v-if="
      this.$router.currentRoute.path == '/evisittool' || 
      this.$router.currentRoute.path == '/evisittoolCompany' ||
      this.$router.currentRoute.path == '/evisittoolCompanyAbout' ||
      this.$router.currentRoute.path == '/evisittoolCompanyContact'
    "></NavbarEvisit>
    <Navbar v-else></Navbar>
    
    <v-main>
      <router-view/>
    </v-main>
    
    <VisitorTracker></VisitorTracker>
    <Footer></Footer>
  </v-app>
</template>

<script>
  import Navbar from "@/components/Global/Navbar.vue";
  import NavbarEvisit from "@/components/Global/NavbarEvisit.vue";
  import Footer from "@/components/Global/Footer.vue";
  import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue"
  export default {
    name: 'App',
    components: {
      Navbar,
      NavbarEvisit,
      Footer,
      VisitorTracker
    },
    data(){
      return {
        currentRouter: this.$router.currentRoute.path,
      }
    },
    methods: {
      
    }
  }
</script>