<template>  
  <v-container>
    <v-row cols="12" class="ma-0 pa-0">
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
        <v-card height="100%" class="d-flex flex-column" flat>

          <p class="title">{{MainHeadliner}}</p>
          <v-spacer></v-spacer>
          <p>One of the consequences of Covid-19 pandemic was that VET schools lost the opportunity to collaborate with companies as a learning place.</p>
          <p>This has been an important part of the VET education structure and DigiVet has therefore developed an easy and accessible tool as a solution to this problem.</p>
          <p>The tool also enables contact with companies from a broader geographic area after the covid-19 situation.</p>
          <p>The tool enables companies to present themself by videos, photos, written information and enables students to ask questions.</p>
          <p>The tool also offers LIVE meetings, such as video chats and text chats with representatives from the companies.</p>
          <!-- <p v-html="MainDescription"></p> -->
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn class="pa-5 buttonStyling" color="#1976D2" block to="/evisittool">DIGIVET E-VISIT EVENT TOOL</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card height="100%" flat>
          <v-img src="img/test.png" contain height="60vh"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data(){
      return {
        MainHeadliner: "E-visit learning tool for improved digital collaboratioin between school, students and companies",
      }
    }
  }
</script>

<style scoped>
  .buttonStyling {
    color: white;
  }
</style>