<template>
  <div class="homePageWidth">
    <FileDialog ref="openFileDialog"></FileDialog>
    <v-row class="ma-0 pa-0">
      
      <!-- Bread Crumbs -->
      <EvisitBreadcrumbs></EvisitBreadcrumbs>

      <!-- Headers -->
      <v-col cols="12">
        <p class="mainHeadliner text-center">{{companyData.title}}</p>

        <v-card flat class="ma-0 pa-0 pt-10">
            <v-card-title class="justify-center ma-0 pa-0">
              <a href="https://gv.no/" target="_blank">
                <v-img src="../../assets/289552581_5358092367581173_6947868880714753572_n.jpg" contain width="150px" height="150px" aspect-ratio="3" class="text-center"></v-img>
              </a>
            </v-card-title>
          </v-card>
        <!-- <pre>{{companyData}}</pre>
        <pre>{{companiesData[0]}}</pre> -->
        <!-- <p class="companyHeadliner text-center">{{companyData.title}}</p> -->
      </v-col>

      <!-- Visit Button -->
      <v-col cols="12">
        <v-card flat>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn :href="companyData.link" target="_blank" v-if="companyData.link != ''" color="#1976D2" class="px-15 py-5 buttonStyle">360 Virtual Visit</v-btn>
            <v-btn v-else disabled depressed class="px-15 py-5">360 Virtual Visit</v-btn>  -->
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Header -->
      <v-col cols="12">
        <p class="mainHeadliner text-center">About us</p>
      </v-col>

      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- About us text -->
      <v-col cols="12">
        <p class="subHeadliners mb-0">Who Are We</p>
        <p>{{companiesData[0].info}}</p>
        <p class="subHeadliners mb-0">HomePage</p>
        <a :href="companiesData[0].homepage_link">{{companiesData[0].homepage_link}}</a>
        <!-- <p>{{companyData.homepage_link}}</p> -->
        <p class="subHeadliners mb-0 mt-3">Opening Dates</p>
        <p>
          <span>{{companyData.start_date}}</span>
          <span> - </span>
          <span>{{companyData.end_date}}</span>
        </p>
      </v-col>
      <!-- <v-col cols="12" xl="9" lg="9" md="9" sm="12" xs="12" class="mt-15">
        <p class="subHeadliners mb-0">Who Are We</p>
        <p>{{companyData.about.whoAreWe}}</p>

        <p class="subHeadliners mb-0">What we do</p>
        <p>{{companyData.about.whatWeDo}}</p>

        <p class="subHeadliners mb-0">Fun Facts About us</p>
        <p>{{companyData.about.funFacts}}</p>
      </v-col> -->

      <v-col cols="12" class="mb-15 mt-15"></v-col>

      <!-- Files -->
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
        <v-card v-if="companyData.participant_files != ''">
          <v-row class="ma-0 pa-0">
            <p class="pl-5 pt-5" v-if="companyData.participant_files == ''">No Files Added</p>
            <template v-else>
              <p class="pl-5 pt-5">Click button below to see all files</p>
            </template>
          </v-row>
          <!-- If more than 5 notes, offer to see all of them displayed in a dialog -->
          <!-- <v-card-actions>
            <v-btn @click="$refs.openFileDialog.openDialog()">See all Files</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <!-- About us Video -->
      <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="ma-0 pa-0">
        <!-- <iframe v-if="companyData.about.aboutUsVideo" width="460" height="300" :src="companyData.about.aboutUsVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </v-col>

      <!-- Social Media -->
      <EvisitSocialMedia class="mt-15"></EvisitSocialMedia>

    </v-row>
  </div>
</template>

<script>
import FileDialog from "@/components/Evisit/EvisitFilesDialog.vue"
import EvisitBreadcrumbs from "@/components/Evisit/EvisitBreadcrumbs.vue"
import EvisitSocialMedia from "@/components/Evisit/EvisitSocialMedia.vue"
  export default {
    components: {
      FileDialog,
      EvisitBreadcrumbs,
      EvisitSocialMedia
    },
    data(){
      return {
        companyData: "",
        test: 'https://www.youtube.com/embed/jfKfPfyJRdk'
      }
    },
    mounted(){
      this.getCompanyData();
    },
    methods: {
      // Get Localstorage Company Data
      getCompanyData(){
        this.companyData = JSON.parse(localStorage.getItem('key'));
        this.companiesData = JSON.parse(localStorage.getItem('companiesToDisplay'));
      }
    }
  }
</script>

<style scoped>
  .homePageWidth {
    width: 70vw; 
    margin:auto auto;
  }
  
  .buttonStyling {
    color: white;
  }
  
  .mainHeadliner {
    font-size: 34px;
    font-weight: bold;
  }
  
  .companyHeadliner {
    font-size: 22px;
  }

  .subHeadliners {
    font-size: 20px;
    font-weight: bold;
  }

  .buttonStyle {
    color: white;
  }
  
  </style>