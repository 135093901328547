<template>
  <div class="homePageWidth mb-15">
    <v-row class="ma-0 pa-0">

      <!-- Bread Crumbs -->
      <EvisitBreadcrumbs></EvisitBreadcrumbs>

      <v-col cols="12">
        <p class="mainHeadliner text-center">DIGIVET e-visit Event</p>
        <p class="companyHeadliner text-center">Choose a company logo to visit</p>
        <!-- <v-btn @click="getCompaniesToDisplayThree()">asdadada</v-btn> -->
      </v-col>

      <!-- <pre>{{companiesToDisplay}}</pre> -->
      <!-- -- -->
      <!-- <pre>{{mainCompanyToDisplay}}</pre> -->
      <!-- <pre>{{testtt}}</pre> -->

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(company, companyIndex) in mainCompanyToDisplay[0]" :key="companyIndex" class="mb-15">
        <v-card class="pa-5" height="100%" >
          <v-card-title class="justify-center">
            <!-- <v-img v-if="company.logo" :src="company.logo" contain width="150px" aspect-ratio="3" class="text-center"></v-img> -->
            <v-img src="../../assets/289552581_5358092367581173_6947868880714753572_n.jpg" contain width="150px" aspect-ratio="3" class="text-center"></v-img>
            <!-- <span v-else></span> -->
          </v-card-title>
          <p class="title text-center" v-if="company.title">{{company.title}}</p>
          <p class="text-center" v-if="company.info">{{company.info}}</p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn block color="#1976D2" class="pa-5 buttonStyling" @click="pushAndOpenCompany(company, companiesToDisplay)">View</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>


      <!-- Dummy -->
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="mb-15">
        <v-card class="pa-5" height="100%" >
          <v-card-title class="justify-center">
            <v-img src="../../assets/photo_2023-01-25_13-50-14.jpg" contain width="150px" aspect-ratio="3" class="text-center"></v-img>
            <!-- <span v-else></span> -->
          </v-card-title>
          <p class="title text-center">Quidgest</p>
          <p class="text-center">Company visit to Quidgest</p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn block color="#1976D2" class="pa-5 buttonStyling" @click="goToNextPage()">View</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import EvisitBreadcrumbs from "@/components/Evisit/EvisitBreadcrumbs.vue"
  export default {
    components: {
      EvisitBreadcrumbs
    },
    data(){
      return {
        accessKey:window.btoa('bac436b32a36431bb437b9509b6d3495'),
        mainCompanyToDisplay: [],
        companiesToDisplay: [],
        testtt: [],
        test: this.$router.currentRoute.path,
        readTheData: "",
        quidgestData:[
          {
            "title": "Quidgest",
            "images": "",
            "info": "yes",
            "dummyData": "yes"
          }
        ],


        exampleCompanies: [
          {
            logo: "logos/PriosLogo.webp",
            title: "Prios software",
            section: "Company sector",
            link: "https://prios.no",
            liveChat: "",
            about: {
              whoAreWe: "Kunnskap og kvalitet skaper morgendagens næringsliv",
              whatWeDo: "Som næringslivets foretrukne samarbeidspartner er Prios Kompetanse genuint opptatt av å levere kvalitetsveiledning, rådgivning og opplæring i alle ledd.",
              funFacts: "We are Awesome",
              aboutUsVideo: "https://www.youtube.com/embed/jfKfPfyJRdk"
            },
            files: [
              {
                file: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/8c2dbdf9-a28a-46ee-a5aa-a6a4bb4bd15a.pdf",
                name: "Effective Mathematics Teaching Practices"
              }
            ],
            images: [
              { image: "logos/PriosLogo.webp" },
            ],
            socialMedia: {
              linkedIn: "https://no.linkedin.com/company/prioskompetanse",
              facebook: "https://www.facebook.com/prioskompetanse",
              instagram: "",
              youtube: "https://www.youtube.com/channel/UCfLQnVe9_4XK3CppAnHMapA",
              twitter: ""
            },
            contactInfo: {
              email: "post@prios.no",
              phone: "+ 47 930 61 091",
              country: "Norway",
              street: "Jæktskippergata 8",
              areaCode: "7725",
              city: "Steinkjer",
            }
          },
          {
            logo: "logos/PriosLogo.webp",
            title: "Bobs burger",
            section: "Company sector",
            link: "",
            liveChat: "",
            about: {
              whoAreWe: "",
              whatWeDo: "",
              funFacts: "",
              aboutUsVideo: "https://www.youtube.com/embed/jfKfPfyJRdk"
            },
            files: [],
            images: [
              { image: "logos/PriosLogo.webp" },
              { image: "img/test.png" },
              { image: "logos/PriosLogo.webp" },
            ],
            socialMedia: {
              linkedIn: "",
              facebook: "",
              instagram: "",
              youtube: "",
              twitter: ""
            },
            contactInfo: {
              email: "",
              country: "",
              street: "",
              areaCode: "",
              city: "",
            }
          },
          {
            logo: "logos/PriosLogo.webp",
            title: "Prios software",
            section: "Company sector",
            link: "",
            liveChat: "",
            about: {
              whoAreWe: "",
              whatWeDo: "",
              funFacts: "",
              aboutUsVideo: ""
            },
            files: [],
            images: [],
            socialMedia: {
              linkedIn: "",
              facebook: "",
              instagram: "",
              youtube: "",
              twitter: ""
            },
            contactInfo: {
              email: "",
              country: "",
              street: "",
              areaCode: "",
              city: "",
            }
          },
          {
            logo: "logos/PriosLogo.webp",
            title: "Prios software",
            section: "Company sector",
            link: "",
            liveChat: "",
            about: {
              whoAreWe: "",
              whatWeDo: "",
              funFacts: "",
              aboutUsVideo: ""
            },
            files: [],
            images: [],
            socialMedia: {
              linkedIn: "",
              facebook: "",
              instagram: "",
              youtube: "",
              twitter: ""
            },
            contactInfo: {
              email: "",
              country: "",
              street: "",
              areaCode: "",
              city: "",
            }
          },
          {
            logo: "logos/PriosLogo.webp",
            title: "Prios software",
            section: "Company sector",
            link: "",
            liveChat: "",
            about: {
              whoAreWe: "",
              whatWeDo: "",
              funFacts: "",
              aboutUsVideo: ""
            },
            files: [],
            images: [],
            socialMedia: {
              linkedIn: "",
              facebook: "",
              instagram: "",
              youtube: "",
              twitter: ""
            },
            contactInfo: {
              email: "",
              country: "",
              street: "",
              areaCode: "",
              city: "",
            }
          },
          {
            logo: "logos/PriosLogo.webp",
            title: "Prios software",
            section: "Company sector",
            link: "",
            liveChat: "",
            about: {
              whoAreWe: "",
              whatWeDo: "",
              funFacts: "",
              aboutUsVideo: ""
            },
            files: [],
            images: [],
            socialMedia: {
              linkedIn: "",
              facebook: "",
              instagram: "",
              youtube: "",
              twitter: ""
            },
            contactInfo: {
              email: "",
              country: "",
              street: "",
              areaCode: "",
              city: "",
            }
          }
        ]
      }
    },
    mounted(){
      this.getCompaniesToDisplay();
      this.getCompaniesToDisplayTwo();
    },
    methods: {

      // Dummy Data - Untill FIXED
      goToNextPage(){
        this.$router.push('evisittoolCompanys');
      },



      // Push selected data to localstorage and move to company clicked
      pushAndOpenCompany(data, dataTwo){
        localStorage.setItem('key', JSON.stringify(data));
        localStorage.setItem('companiesToDisplay', JSON.stringify(dataTwo));
        this.$router.push('evisittoolCompany');
      },
      getCompaniesToDisplay(){
        this.$http.get(`https://app.followup.prios.no/api/prios_events/events?tenant_id=${101}`,{headers:{Tempaccess:this.accessKey}}).then((response)=> {
          console.log(response.data);
          this.mainCompanyToDisplay.push(response.data);
        })
      },
      
      /*
        events
        files
        event_users
        participants
        company_info
        participation_stands
      */
      getCompaniesToDisplayTwo(){
        this.$http.get(`https://app.followup.prios.no/api/prios_events/participants?mode=getselectedeventandinfopreview&tenant_id=${101}`,{headers:{Tempaccess:this.accessKey}}).then((response)=> {
          console.log(response.data);
          this.companiesToDisplay.push(response.data);
        })
      },

      getCompaniesToDisplayThree(){
        // this.$http.get(`https://app.followup.prios.no/api/prios_events/company_info`,{headers:{Tempaccess:this.accessKey}}).then((response)=> {
        this.$http.get(`https://app.followup.prios.no/api/prios_events/files?tenant_id=${101}`,{headers:{Tempaccess:this.accessKey}}).then((response)=> {
          console.log(response.data);
          this.testtt.push(response.data);
        })
      }
    }
  }
</script>

<style scoped>
.homePageWidth {
  width: 70vw; 
  margin:auto auto;
}

.buttonStyling {
  color: white;
}

.mainHeadliner {
  font-size: 26px;
  font-weight: bold;
}

.companyHeadliner {
  font-size: 22px;
}
</style>