<template>
  <v-container>
    <NewsDialog ref="newsDialogParent"></NewsDialog>
    <div class="title mt-n4">Latest News:</div>
    <v-row>
      <template v-if="!hiddenSkeletons">
        <!-- <v-col v-for="skeleton in howManySkeletons" :key="skeleton" cols="12" sm="12"  md="6">
          <v-skeleton-loader type="image, article"> </v-skeleton-loader>
        </v-col> -->
      </template>
      <template v-else>
        <v-col v-for="article in currentNews" :key="article.id" cols="12" md="6" @click="$refs.newsDialogParent.openDialog(article)">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 2" height="100%" class="pb-2" >
              <v-img v-if="article.mediaurl !== ''" aspect-ratio="1.77" :src="article.mediaurl"></v-img>
              <v-card-text>
                <div class="title">
                  {{ article.title }}
                </div>
                <div>
                  {{ article.preview }}
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
    <v-dialog v-model="articleBoxMod" max-width="800px">
      <v-card tile class="pb-2">
        <v-btn absolute fab x-small top right class="mt-4 mr-n4 elevation-0" @click="articleBoxMod = false">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-img v-if="articleBox.mediaurl !== ''" :src="articleBox.mediaurl" contain></v-img>
        <v-card-text class="pt-2">
          <div class="title">
            {{ articleBox.title }}
          </div>
          <div class="subtitle-2 py-3">
            <!-- <div>
              {{ 'Published: ' + $moment(articleBox.created).format('Do MMMM YYYY') }}
            </div> -->
            <!-- <div v-if="articleBox.created !== articleBox.updated">
              {{ 'Updated: ' + $moment(articleBox.updated).format('Do MMMM YYYY, h:mm') }}
            </div> -->
          </div>
          <div v-html="articleBox.content"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import NewsDialog from "@/components/NewsDialog.vue"
export default {
  components: {
    NewsDialog
  },
  data() {
    return {
      hiddenSkeletons: false,
      howManySkeletons: 4,
      articleBoxMod: false,
      articleBox: [],
      news: []
    }
  },
  computed: {
    currentNews() {
      // Show only last 6 articles
      return this.news.slice(0, 4) 
    }
  },
  mounted() {
    // this.$axios.get('/news/journalism/').then((response) => {
    //   this.news = response.data
    //   this.hiddenSkeletons = true
    // })
    this.$http.get('https://app.followup.prios.no/api/news/e55393cb-8f32-455e-8f33-112d2bd85f33/').then((response) => {
      this.news = response.data
      this.hiddenSkeletons = true
    })
  },
  scrollToTop() {
    window.scrollTo(0,0);
  }
}
</script>
