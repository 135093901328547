<template>
  <div class="homePageWidth">
    <v-row class="ma-0 pa-0">

      <!-- Bread-crumbs -->
      <EvisitBreadcrumbs></EvisitBreadcrumbs>

      <!-- Headers -->
      <v-col cols="12">
        <p class="mainHeadliner text-center">Digivet e-visit event</p>
        <p class="companyHeadliner text-center">{{companyData.title}}</p>
      </v-col>


      <!-- <pre>{{companyData}}</pre>
      <pre>{{companiesData[0]}}</pre> -->

      <!-- Visit Button -->
      <!-- <v-col cols="12" class="mb-15">
        <v-card flat>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :href="companyData.link" target="_blank" v-if="companyData.link != ''" color="#1976D2" class="px-15 py-5 buttonStyle">360 Virtual Visit</v-btn>
            <v-btn v-else disabled depressed class="px-15 py-5">360 Virtual Visit</v-btn> 
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col> -->

      <!-- Contact Header -->
      <v-col cols="12" class="pb-0">
        <p class="mainHeadliner text-center">Contact Us</p>
      </v-col>

      <!-- Live Chat Button -->
      <!-- <v-col cols="12">
        <v-card flat>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :to="companyData.liveChat" target="_blank" v-if="companyData.liveChat != ''" color="#36D399" class="px-10 py-5">Live Chat</v-btn>
            <v-btn v-else disabled depressed class="px-15 py-5">Live Chat</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col> -->

      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- Contact Data -->
      <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="mb-15 mt-15">
        <v-card flat>
          <p class="mb-0">Phone: {{companiesData[0].tenant_phone}} </p>
          <!-- <p> {{companyData.contactInfo.email}} </p>
          <p class="mb-0"> {{companyData.contactInfo.street}} </p>
          <p class="mb-0"> {{companyData.contactInfo.areaCode}} {{companyData.contactInfo.city}}</p>
          <p> {{companyData.contactInfo.country}} </p> -->
        </v-card>
      </v-col>

      <!-- Social Media -->
      <EvisitSocialMedia></EvisitSocialMedia>
      
    </v-row>
  </div>
</template>

<script>
import EvisitBreadcrumbs from "@/components/Evisit/EvisitBreadcrumbs.vue"
import EvisitSocialMedia from "@/components/Evisit/EvisitSocialMedia.vue"
  export default {
    components: {
      EvisitBreadcrumbs,
      EvisitSocialMedia
    },
    data(){
      return {
        companyData: ""
      }
    },
    mounted(){
      this.getCompanyData();
    },
    methods: {
      // Get Localstorage Company Data
      getCompanyData(){
        this.companyData = JSON.parse(localStorage.getItem('key'));
        this.companiesData = JSON.parse(localStorage.getItem('companiesToDisplay'));
      }
    }
  }
</script>

<style scoped>
  .homePageWidth {
    width: 70vw; 
    margin:auto auto;
  }

  .mainHeadliner {
    font-size: 34px;
    font-weight: bold;
  }

  .companyHeadliner {
    font-size: 22px;
  }

  .buttonStyle {
    color: white;
  }
</style>