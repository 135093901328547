<template>
  <div>

    <!-- NavBar -->
    <v-app-bar app dark color="" elevate-on-scroll class="pt-0">
      <v-spacer class="hidden-md-and-up" />
      <!-- Navbar Logo -->
      <a class="hidden-sm-and-down pt-2" style="height:100%; display: flex; align-items: center" href="/">
        <img alt="Digi-Vet" contain src="/logos/digiVetLogo.png" transition="scale-transition" style="max-width:20vw; max-height:100%"/>
      </a>
      <a class="hidden-md-and-up hidden-xs-only" href="/">
        <v-img alt="Digi-Vet" contain max-height="50px" src="/logos/digiVetLogo.png" transition="scale-transition"/>
      </a>
      <a class="hidden-sm-and-up" href="/">
        <v-img alt="Digi-Vet" class="shrink" contain max-height="50px" max-width="100px" src="/logos/digiVetLogo.png" transition="scale-transition"/>
      </a>
      <v-spacer></v-spacer>
   
    </v-app-bar>
  </div>
</template>


<script>
export default {
  name: 'App',

  data(){
    return {
    
    }
  }
};
</script>