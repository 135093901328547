<template>
  <div>

    <!-- NavBar -->
    <v-app-bar app dark color="" elevate-on-scroll class="pt-0">
      <!-- Hamburger Menu on Tablet and down -->
      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click.stop="menuDrawer = !menuDrawer"></v-app-bar-nav-icon>
      </v-toolbar-items>
      <v-spacer class="hidden-md-and-up" />
      <!-- Navbar Logo -->


      <a class="hidden-sm-and-down pt-2" style="height:100%; display: flex; align-items: center" href="/">
        <img alt="Digi-Vet" contain src="/logos/digiVetLogo.png" transition="scale-transition" style="max-width:20vw; max-height:100%"/>
      </a>
      <a class="hidden-md-and-up hidden-xs-only" href="/">
        <v-img alt="Digi-Vet" contain max-height="50px" src="/logos/digiVetLogo.png" transition="scale-transition"/>
      </a>
      <a class="hidden-sm-and-up" href="/">
        <v-img alt="Digi-Vet" class="shrink" contain max-height="50px" max-width="100px" src="/logos/digiVetLogo.png" transition="scale-transition"/>
      </a>
      <v-spacer></v-spacer>
      <!-- <p>{{this.$router.currentRoute.path}}</p> -->
      <!-- Navbar Links -->
      <v-toolbar-items v-for="item in mainMenu" :key="item.id" class="hidden-sm-and-down">
        <v-btn v-if="item.path.includes('http')" text :href="item.path" target="_blank" exact class="text-none">
          <span> {{ item.title }} </span>
          <v-icon class="ml-1">launch</v-icon>
        </v-btn>
        <v-btn v-else text :to="item.path" exact class="text-none">{{ item.title }}</v-btn>
      </v-toolbar-items>
      <!-- Login Button -->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">
          <!-- <v-icon>lock</v-icon> -->
          <div>Login</div>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-md-and-up">
        <v-btn icon href="https://app.followup.prios.no/#/login" target="_blank">
          <!-- <v-icon>lock</v-icon> -->
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  
    <!-- Sidebar on Tablet and down -->
    <v-navigation-drawer v-model="menuDrawer" app temporary>
      <v-list dense nav>
        <div v-for="item in mainMenu" :key="item.id">
          <v-list-item v-if="item.path.includes('http')" :href="item.path" target="_blank">
            <!-- <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else :to="item.path">
            <!-- <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>
export default {
  name: 'App',

  data(){
    return {
      menuDrawer: false,
      mainMenu: [
        { id: 1, icon: 'home', title: 'Home', path: '/' },
        { id: 2, icon: 'info', title: 'About Project', path: '/about' },
        { id: 3, icon: 'widgets', title: 'News', path: '/news' },
        { id: 4, icon: 'assignment', title: 'Tutorial resources', path: '/training' },
        { id: 5, icon: 'description', title: 'Outputs', path: '/outputs' },
        { id: 5, icon: 'description', title: 'Mapping tool', path: '/mapping' },
        { id: 6, icon: 'launch', title: 'E-Visit', path: '/evisit' }
      ]
    }
  }
};
</script>
<style>
  a {
    text-decoration: none;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding-top: 0px !important;
  }
  </style>
  