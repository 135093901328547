<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" xl="10">
        <div class="headline"> About Digi-Vet </div>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="5">
        <v-card height="100%">
          <!-- <div style="display:flex; justify-content:center;">

          </div> -->
          <v-row  justify="center">
            <v-col cols="2"></v-col>
            <v-col class="hidden-sm-and-down pt-2">
              <img alt="Digi-Vet" contain src="/logos/digiVetLogo.png" transition="scale-transition" height="100px"/>
            </v-col>
            <v-col class="hidden-md-and-up hidden-xs-only">
              <img alt="Digi-Vet" contain src="/logos/digiVetLogo.png" transition="scale-transition" height="75px"/>
            </v-col>
            <v-col class="hidden-sm-and-up">
              <img alt="Digi-Vet" contain src="/logos/digiVetLogo.png" transition="scale-transition" height="50x"/>
            </v-col>
          </v-row>
          <!-- <v-img height="256px" contain src="img/logos/digiVetLogo.png" lazy-src="img/logos/digiVetLogo.png"></v-img> -->
          <v-card-text>
            <!-- <p class="font-weight-bold"> {{ about.lead }} </p>
            <div v-html="about.content"></div> -->
            <p>
              Digi-VET will develop concrete knowledge, description, criteria to better help VET Institutions to develop 
              their own digital educational readiness guidelines and in parallel a mapping tool based on known criteria to
              score the institutions and give direction for their further work. 
            </p>
             <p>
              Project will start to develop concrete learning content within usage of digital educational resources and implementation guidelines.  
              All this put together will be our methodology package, which contain a handbook of methods, tools, guidelines and checklists within digital pedagogical readiness competences.
              E-Activites within 5 educational resources and  learning modules giving students holistic learning in order to support digital transformation processes among schools. 
              Is important for VET education and will be important part of implementation process in project.

              Three Joint Staff training events (LTT’s) in Portugal, Germany and Norway for 2-3 teachers from each partner in order to learn and improve the created solutions will be a 
              very important activity to both tests, evaluate and improve our work.
              Even more important is the learning among participating teachers, since they will be active parts to implement the knowledge, tools and software in pilot projects and workshops after the LTT.
            </p>
            <p>
              In parallel with this work, the needed E-VISIT tool will be developed and tested, first within partnership, then among all associated partner and companies’ partners cooperate with. 
              The learning tool will give much needed improved digital collaboration between school, students and companies as well as open a better opportunity to visit work learning situation outside 
              the schools neighbourhood, thus supporting the idea of a common European work market.

              In total this will be a strong developed packed, giving both participating VET institutions an increased digital educational readiness covering all learning aspects of their education, 
              but also an important packed possible to use as a whole or just part of it for schools outside the partnership. 
            </p>
            <p class="font-weight-bold">
              Target group in this project will be VET institutions and its management and teachers. 
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="5">
        <v-row>
          <v-col cols="12">
            <p class="title text-center mb-0">Aims of project</p>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pa-0 ma-1">
            <v-card class="pa-2" elevation="3">
              <p class="title text-center mb-0 pb-1">Aim 1</p>
              <v-divider></v-divider>
              <p class="pt-3">
                Identify criteria for VET institutions digital educational readiness and create guidelines and checklist to 
                implement digital pedagogical readiness competences among educators and schools as an organization.
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0 ma-1">
            <v-card class="pa-2" elevation="3">
              <p class="title text-center mb-0 pb-1">Aim 2</p>
              <v-divider></v-divider>
              <p class="pt-3">
                Create mapping tool supporting the developed criteria to score the Digital pedagogical preparedness.
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-card class="pa-2 ma-1" elevation="3">
              <p class="title text-center mb-0 pb-1">Aim 3</p>
              <v-divider></v-divider>
              <p class="pt-3">
                Create E-Activities within digital pedagogical practical knowledge and digital pedagogical guidelines with visual 
                supportive steps for practical creation of learning modules giving students holistic learning experience. 
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-card class="pa-2 ma-1" elevation="3">
              <p class="title text-center mb-0 pb-1">Aim 4</p>
              <v-divider></v-divider>
              <p class="pt-3">
                Create easy to use, low and secure E-VISIT learning tool for improved digital collaboration between school, students and companies. 
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="10">
        <Partners />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Partners from "../components/Partners.vue"

export default {
  name: 'About',
  components: {
    Partners
  }
}
</script>
