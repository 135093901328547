<template>
  <div class="homePageWidth mb-15">
    <v-card flat>
      <v-row class="ma-0 pa-0">

        <!-- Breadcrumbs -->
        <EvisitBreadcrumbs></EvisitBreadcrumbs>

         <!-- Headers -->
         <v-col cols="12">
          <p class="mainHeadliner text-center">Digivet e-visit event</p>
          <p class="companyHeadliner text-center" v-if="companyData.title">{{companyData.title}}</p>
          <!-- <p>{{companyData}}</p> -->
        </v-col>


        <!-- <pre>{{companiesData}}</pre> -->

        <!-- Visit Button -->
        <v-col cols="12" class="mb-15">
          <v-card flat class="mt-0 pt-0">
            <v-card-title class="justify-center">
              <a href="https://gv.no/" target="_blank">
                <v-img src="../../assets/289552581_5358092367581173_6947868880714753572_n.jpg" contain width="150px" height="150px" aspect-ratio="3" class="text-center"></v-img>
              </a>
            </v-card-title>
          </v-card>
          <v-card flat>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn :href="companyData.link" target="_blank" v-if="companyData.link != ''" color="#1976D2" class="px-15 py-5 buttonStyle">360 virtual visit</v-btn> -->
              <!-- <v-btn v-else disabled depressed class="px-15 py-5">360 virtual visit</v-btn>  -->
              <v-btn href="https://prios360.netlify.app/?667e8e3d-74e4-4a62-8305-9da8c44d413f" target="_blank" color="#1976D2" class="px-15 py-5 buttonStyle">360 virtual visit</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        
        <!-- Images -->
        <v-col col="12" class="mt-0 pt-0">
          <!-- No Image Found -->
          <template v-if="companyData.images == ''">
            Empty
          </template>
          <!-- Single Image Found-->
          <!-- <template v-if="companyData.images.length == 1">
            <v-card flat>
              <v-card-title class="justify-center">
                <v-img :src="companyData.images[0].image" contain width="150px" height="150px" aspect-ratio="3" class="text-center"></v-img>
              </v-card-title>
            </v-card>
          </template> -->
          <!-- Carousel - If more than 1 image-->
          <!-- <template v-if="companyData.images.length > 1">
            <v-carousel hide-delimiters height="150px">
              <v-carousel-item v-for="(item,itemIndex) in companyData.images" :key="itemIndex" :src="item.image" contain width="auto"></v-carousel-item>
            </v-carousel>
          </template> -->

        </v-col>
        

        <!-- About and Contact Button -->
        <v-col cols="12" class="mb-15">
          <v-card flat>
            <v-card-actions class="mb-15">
              <v-spacer></v-spacer>
              <v-btn v-if="companyData.info" class="px-15 py-5 mr-4" color="#36D399" to=/evisittoolCompanyAbout>About</v-btn>
              <v-btn v-else class="px-15 py-5 mr-4" disabled to=/evisittoolCompanyAbout>About</v-btn>
              <!-- <v-btn class="px-15 py-5 ml-4" color="#3ABFF8" to="/evisittoolCompanyContact">Contact Us</v-btn> -->
              <v-btn class="px-15 py-5 ml-4" color="#36D399" to="/evisittoolCompanyContact">Contact Us</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- Social Media -->
        <EvisitSocialMedia></EvisitSocialMedia>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import EvisitBreadcrumbs from "@/components/Evisit/EvisitBreadcrumbs.vue"
import EvisitSocialMedia from "@/components/Evisit/EvisitSocialMedia.vue"
  export default {
    components: {
      EvisitBreadcrumbs,
      EvisitSocialMedia
    },
    data(){
      return {
        companyData: {},
        companiesData: ""
      }
    },
    mounted(){
      this.getCompanyData();
    },
    methods: {
      // Get company data from localstore. making sure it are saved in case of a page refresh or something.
      getCompanyData(){
        this.companyData = JSON.parse(localStorage.getItem('key'));
        this.companiesData = JSON.parse(localStorage.getItem('companiesToDisplay'));
      }
    }
  }
</script>

<style scoped>
.homePageWidth {
  width: 70vw; 
  margin:auto auto;
}
.mainHeadliner {
  font-size: 34px;
  font-weight: bold;
}

.companyHeadliner {
  font-size: 22px;
}
.buttonStyle {
  color: white;
}
</style>