<template>
  <v-container>
    <!-- <VisitorTracker></VisitorTracker> -->
    <v-row class="justify-center">
      <v-col cols="12" sm="6" md="6" lg="6" xl="5">
        <v-card>
          <!-- Youtube Video -->
          <div class="video-container">
            <iframe src="https://www.youtube.com/embed/pmV5SQjflwc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <!-- Page Title and Description -->
          <v-card-text>
            <p class="title">VET Digitalisation</p>
            <p> 
              Knowledge about and ability to use open source learning resources in a good pedagogical way and to equip teachers/educators with the skills / knowledge of developing their own digital materials, is in great demand. 
              Likewise, having digital access to companies as a source for learning, collaborating and job demands is essential in all VET training since we have a common European work market.
            </p>
            <p> The DIGIVET project aims to approach the actual needs and challenges linked to the use of digital education within VET schools/colleges/Institution from Europe. </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="5">
        <v-row>
          <v-col cols="12" class="pa-4">
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="ma-0 pa-0 pa-1">
                <v-card class="pa-5 cardStyling" to="/mapping">
                  <p>Test the mapping tool</p>
                  <p>How is the digital VET Readiness of your institution</p>
                </v-card>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="ma-0 pa-0 pa-1">
                <v-card class="pa-5 cardStyling" to="/training">
                  <p>Find learning tutorials</p>
                  <p>How to foster learning and collaboration in digital environment</p>
                </v-card>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="ma-0 pa-0 pa-1">
                <v-card class="pa-5 cardStyling" to="/outputs">
                  <p>Think strategic</p>
                  <p>Find Readiness Criteria for Schools and Teachers in the transformation towards Digital Teaching and Learning</p>
                </v-card>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="ma-0 pa-0 pa-1">
                <v-card class="pa-5 cardStyling" to="/outputs">
                  <p>Explore toolkit</p>
                  <p>Learn from Teachers Digital Knowledge Toolkit. How use different digital tools and educational resources</p>
                </v-card>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0 pa-1">
                <v-card class="pa-5 cardStyling" to="/evisit">
                  <p>Fancy a digital visit</p>
                  <p>Explore how VET Students can learn and collaborate with companies inline in the E-visit tool</p>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <News />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="10">
        <Partners />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import News from "@/components/News.vue"
  import Partners from "@/components/Partners.vue"
  import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue"
  export default {
    name: 'Home',

    components: {
      News,
      Partners,
      VisitorTracker
    },
  }
</script>


<style scoped>
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .cardStyling {
    min-height: 11em; 
    background-color: lightblue; 
    border: solid #1976D2 2px; 
    cursor: pointer;
  }
  </style>
  