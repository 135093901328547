<template>
  <v-col cols="12" class="mb-15" v-if="
      companyData.linkedin_link != '' || 
      companiesData[0].linkedin_link != '' || 
      companyData.facebook_link != '' || 
      companiesData[0].facebook_link != '' || 
      companyData.instagram_link != '' || 
      companiesData[0].instagram_link != '' || 
      companyData.youtube_link != '' || 
      companiesData[0].youtube_link != '' || 
      companyData.twitter_link != '' ||
      companiesData[0].twitter_link != '' 
    "> 
    <v-row class="ma-0 pa-0">
      <v-col cols="1"></v-col>
      <v-col cols="2" v-if="companyData.linkedin_link || companiesData[0].linkedin_link" >
        <a v-if="companyData.linkedin_link" :href="companyData.linkedin_link" target="_blank">
          <v-img src="img/linkedin.png" contain height="90px"></v-img>
        </a>
        <a v-else-if="companiesData[0].linkedin_link" :href="companiesData[0].linkedin_link" target="_blank">
          <v-img src="img/instagram.png" contain height="90px"></v-img>
        </a> 
      </v-col>

      <v-col cols="2">
        <a href="https://www.facebook.com/gvreklame" target="_blank">
          <v-img src="img/facebookk.png" contain height="90px"></v-img>
        </a> 
      </v-col>
      <!-- <v-col cols="2" v-if="companyData.facebook_link || companiesData[0].facebook_link">
        <a v-if="companyData.facebook_link" :href="companyData.facebook_link" target="_blank">
          <v-img src="img/facebookk.png" contain height="90px"></v-img>
        </a>
        <a v-else-if="companiesData[0].facebook_link" :href="companiesData[0].facebook_link" target="_blank">
          <v-img src="img/facebookk.png" contain height="90px"></v-img>
        </a> 

        
      </v-col> -->
      <v-col cols="2" v-if="companyData.instagram_link || companiesData[0].instagram_link">
        <a v-if="companyData.instagram_link" :href="companyData.instagram_link" target="_blank">
          <v-img src="img/instagram.png" contain height="90px"></v-img>
        </a> 
        <a v-else-if="companiesData[0].instagram_link" :href="companiesData[0].instagram_link" target="_blank">
          <v-img src="img/instagram.png" contain height="90px"></v-img>
        </a> 
      </v-col>
      <v-col cols="2" v-if="companyData.youtube_link || companiesData[0].youtube_link">
        <a v-if="companyData.youtube_link" :href="companyData.youtube_link" target="_blank">
          <v-img src="img/youtube.png" contain height="90px"></v-img>
        </a>
        <a v-else-if="companiesData[0].youtube_link" :href="companiesData[0].instagram_link" target="_blank">
          <v-img src="img/instagram.png" contain height="90px"></v-img>
        </a> 
      </v-col>
      <v-col cols="2" v-if="companyData.twitter_link || companiesData[0].twitter_link">
        <a v-if="companyData.twitter_link" :href="companyData[0].twitter_link" target="_blank">
          <v-img src="img/Twitter2.png" contain height="90px"></v-img>
        </a>
        <a v-else-if="companiesData[0].twitter_link" :href="companiesData[0].twitter_link" target="_blank">
          <v-img src="img/instagram.png" contain height="90px"></v-img>
        </a> 


      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  export default {
    data() {
      return {
        companyData: {}
      }
    },
    mounted(){
      this.getCompanyData();
    },
    methods: {
      // Get Localstorage Company Data
      getCompanyData(){
        this.companyData = JSON.parse(localStorage.getItem('key'));
        this.companiesData = JSON.parse(localStorage.getItem('companiesToDisplay'));
      }
    }
  }
</script>