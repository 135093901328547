<template>
  <div>
     <!-- Read the README for more info -->
    <TrainingDialog ref="openTrainingDialog"></TrainingDialog>

    <v-row class="mb-5 pa-2">
      <!-- <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" v-for="(courseCollection, index) in courseCollections" :key="index" class="pa-3">
        <v-card class="pa-2 ml-4" @click="$refs.openTrainingDialog.openDialog(courseCollection)" height="100%">
          <v-img v-if="courseCollection.background_image" :src="courseCollection.background_image" style="height: 150px;"></v-img>
          <p class="pt-3 text-center title">{{courseCollection.name}}</p>
          <p class="textLimiter">{{courseCollection.description}}</p>
        </v-card>
      </v-col> -->

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card class="pa-2" flat>
          <p class="title text-center">Knowledge construction on collaborative learning</p>
          <p style="min-height: 80px;">1. Knowledge construction on collaborative learning: this tutorial shows collaborative learning as a process  and which key elements involves to facilitate knowledge construction.</p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/AGvbAewSPck" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card class="pa-2" flat>
          <p class="title text-center">Access on motivation</p>
          <p style="min-height: 80px;">2.Access on motivation: this tutorial sees the key factors that can impact the motivation and  how can be influenced.</p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/_W-q-tZhtt4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card class="pa-2" flat>
          <p class="title text-center">Development on cognitive</p>
          <p style="min-height: 80px;">3. This tutorial refers to cognitive development abilities to the growth and maturation of mental processes such as thinking, perception, attention, memory, language, and problem-solving abilities.</p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/plhHAjsGCCA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card class="pa-2" flat>
          <p class="title text-center">Creating space for information exchange</p>
          <p style="min-height: 80px;">4. This tutorial describes the steps on how to create space for information such as: Establishing a clear purpose,Encouraging participation,Providing structure, Cultivating trust and openness.</p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Oe_VpiOI3ZE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card class="pa-2" flat>
          <p class="title text-center">Building a community for online socialization</p>
          <p style="min-height: 80px;">5. This tutorial defines the purpose of the community and target audience, how to buid it, how to promote it, how to angage mambers and how to create opportunities for learning.</p>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/2jQ-GXSEzGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import TrainingDialog from "./TrainingModuleDialog.vue"
export default {
  components:{
    TrainingDialog
  },
  data(){
    return{
      // Access Key to followup API
      accessKey: "YmFjNDM2YjMyYTM2NDMxYmI0MzdiOTUwOWI2ZDM0OTU=",
      // 73 - default 999
      tenant: 101,
      courseCollections:[],

      youtubeVideos: [
        {
          title: "Knowledge construction on collaborative learning",
          description: "",
          youtubeVideo: "https://www.youtube.com/watch?v=AGvbAewSPck"
        },
        {
          title: "Access on motivation",
          description: "",
          youtubeVideo: "https://www.youtube.com/watch?v=_W-q-tZhtt4"
        },
        {
          title: "Development on cognitive",
          description: "",
          youtubeVideo: "https://www.youtube.com/watch?v=plhHAjsGCCA"
        },
        {
          title: "Creating space for information exchange",
          description: "",
          youtubeVideo: "https://www.youtube.com/watch?v=Oe_VpiOI3ZE"
        },
        {
          title: "Building a community for online socialization",
          description: "",
          youtubeVideo: "https://www.youtube.com/watch?v=2jQ-GXSEzGg"
        },
      ]
    }
  },
  mounted(){
    this.getAllCourseCollections();
  },
  methods:{
    // Get all the courses to display on the page
    getAllCourseCollections(){
      this.courseCollections = [];
      this.$http.get(`https://app.followup.prios.no/api/courses/collection?mode=getpubliccoursesbytenant&tenant_id=${this.tenant}&user_id=${null}`,{headers:{tempaccess:this.accessKey}}).then(response =>{
        response.data.forEach(item =>{
          item.collection_content = []
          this.courseCollections.push(item);
        })
      })
    },
  }
}
</script>