<template>
  <div>
    <EvisitCompanyAbout></EvisitCompanyAbout>
  </div>
</template>

<script>
import EvisitCompanyAbout from "@/components/Evisit/EvisitCompanyAbout.vue"
  export default {
    components: {
      EvisitCompanyAbout
    },
    data(){
      return {

      }
    }
  }
</script>