<template>
  <News></News>
</template>

<script>
import News from "@/components/NewsMainPage.vue"
export default {
  components: {
    News
  },
  data() {
    return {

    }
  }
}
</script>