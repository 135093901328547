<template>
  <div class="homePageWidth mb-15">

    <!-- BreadCrumbs -->
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-card flat>
          <!-- First Page - List of all the companies -->
          <template>
            <v-btn class="pa-2" text to="/evisittool" style="margin-top:-4px; color:lightgrey;">E-Visit Tool</v-btn>
          </template>
          <!-- Second Page - Selected a specific Company -->
          <template>
            <span class="title">></span>
            <v-btn class="pa-2" text style="margin-top:-4px;">Quidgest</v-btn>
          </template>
        </v-card>
      </v-col>

      <!-- Headers -->
      <v-col cols="12">
        <p class="mainHeadliner text-center">Digivet e-visit event</p>
        <p class="companyHeadliner text-center">Quidgest</p>
      </v-col>

      <!-- Visit Button -->
      <v-col cols="12" class="mb-15">
        <v-card flat class="mt-0 pt-0">
          <v-card-title class="justify-center">
            <a href="https://quidgest.com/" target="_blank">
              <v-img src="../../../assets/photo_2023-01-25_13-50-14.jpg" contain width="150px" height="150px" aspect-ratio="3" class="text-center"></v-img>
            </a>
          </v-card-title>
        </v-card>
        <v-card flat>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn href="https://www.google.com/maps/@38.7300705,-9.148728,3a,75y,156.01h,89.99t/data=!3m7!1e1!3m5!1sAF1QipP5nopdPRvK6wKfUOp2sXgd8wQiXNCGmMnUENUQ!2e10!3e11!7i6912!8i3456" target="_blank" color="#1976D2" class="px-15 py-5 buttonStyle">360 virtual visit</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- About and Contact Button -->
      <v-col cols="12" class="mb-15">
        <v-card flat>
          <v-card-actions class="mb-15">
            <v-spacer></v-spacer>
            <v-btn class="px-15 py-5 mr-4" color="#36D399" to=/evisittoolCompanyAbouts>About</v-btn>
            <!-- <v-btn class="px-15 py-5 ml-4" color="#36D399" to="/evisittoolCompanyContact">Contact Us</v-btn> -->
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>



      <!-- Social Media -->
      <v-col cols="12" class="mb-15"> 
        <v-row class="ma-0 pa-0">
          <v-col cols="1"></v-col>
          <v-col cols="2">
            <a href="https://www.linkedin.com/company/quidgest/" target="_blank">
              <v-img src="img/linkedin.png" contain height="90px"></v-img>
            </a> 
          </v-col>

          <v-col cols="2">
            <a href="https://www.facebook.com/Quidgest" target="_blank">
              <v-img src="img/facebookk.png" contain height="90px"></v-img>
            </a> 
          </v-col>


        </v-row>
      </v-col>

    </v-row>
  </div>
</template>

<script>
  export default {
    data(){
      return {

      }
    }
  }
</script>

<style scoped>
.homePageWidth {
  width: 70vw; 
  margin:auto auto;
}

.buttonStyling {
  color: white;
}

.mainHeadliner {
  font-size: 26px;
  font-weight: bold;
}

.companyHeadliner {
  font-size: 22px;
}
</style>