<template>
  <div>
    <EvisitToolCompanyContact></EvisitToolCompanyContact>
  </div>
</template>

<script>
import EvisitToolCompanyContact from "@/components/Evisit/EvisitCompanyContact.vue"
  export default {
    components: {
      EvisitToolCompanyContact
    },
    data(){
      return {

      }
    },
  }
</script>