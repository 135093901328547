<template>
  <div>
    <EvisitCompany></EvisitCompany>
  </div>
</template>

<script>
import EvisitCompany from "@/components/Evisit/EvisitCompany.vue"
  export default {
    components:{
      EvisitCompany
    },
    data(){
      return {

      }
    }
  }
</script>