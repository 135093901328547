<template>
  <div class="container">
    <p class="title text-center mt-10 mb-10">Mapping tool</p>
    <p class="title text-center">Areas and indicators for the assessment of digital VET Readiness of educational institutions.</p>
    <p>
      The initiatives and processes which lead to an active development of strategies to use digital tools for institutional change and educational enhancement are conceptualized 
      here as being part of an overall school developmental strategy. <br>
      The mapping tool are divided into section that outlines some major areas of strategic school development as well as the related tasks and challenges which are 
      considered vital for addressing the intended changes towards a comprehensive, 
      active and future oriented educational response to the overall digital transformation of society, 
      industry and accompanying lifestyles and attitudes which are subject to educational efforts. 
    </p>
    <p class="mb-0 pb-0">
      The sections is: 
    </p>
    <p class="ml-5 mt-0 pt-0">
      · Normative and Strategical Perspective <br>
      · Organizational Development <br>
      · Human Resources Development <br>
      · Cooperations <br>
      · Infrastructure/ Technology <br>
      · Enhancing Assessments
    </p>
    <p>You will for some questions find help text when clicking on the Information icon. The final results are provided in a SWOT model, enabling the school to deal with the right issues to improve them self.</p>
    
    <!-- <v-row>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(card, index) in mappingCards" :key="index">
        <v-card>
          <v-card-title>
            {{ card.title }}
          </v-card-title>
          <v-card-text>
            {{ card.description }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="$refs.openMappingDialog.openingMappingForm(card.id)">
              Open Mapping Tool
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->

    <v-btn @click="$refs.openMappingDialog.openingMappingForm(196)" large dark class="mt-15"> Start the Assessment Tool </v-btn>
    <MappingToolMainComponent ref="openMappingDialog" />




    <!-- <v-btn @click="$refs.openMappingDialog.openingMappingForm()" large dark class="mt-15"> Start the Assessment Tool </v-btn>

    <MappingToolsDialog ref="openMappingDialog"></MappingToolsDialog> -->
  </div>
</template>

<script>
import MappingToolMainComponent from '@/components/MappingTool/MappingToolMainComponent.vue';
import MappingToolsDialog from "@/components/MappingTool/MappingToolsDialog.vue"

export default {
  components: {
    MappingToolMainComponent,
    MappingToolsDialog
  },
  data() {
    return {
      mappingCards: [
        {
          title: "Digital Friendly Label English",
          description: "Digital Friendly Label English",
          id: 196,
          // flag: '/flags/england.png'
        },
      ]
    }
  }
}

</script>