<template>
  <v-dialog v-model="fileDialog" width="700px">
    <v-card class="pa-5">
      <p class="title text-center">Files</p>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" v-for="(file, fileIndex) in companyData.files" :key="fileIndex">
          <p :to="file.file" target="_blank"> {{file.name}}</p>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>¨

<script>

  export default {
    data(){
      return {
        companyData: {},
        fileDialog: false,
      }
    },
    mounted(){
      this.getCompanyData();
    },
    methods: {
      // Open Dialog
      openDialog(){
        this.fileDialog = true;
      }, 
      // Get Localstorage Company Data
      getCompanyData(){
        this.companyData = JSON.parse(localStorage.getItem('key'));
      }
    }
  }
</script>