<template>
  <v-footer color="#1976D2" dark class="pb-5 pt-5">
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-img aspect-ratio="1.7777777" max-height="50px" contain src="img/Founder_Logo.png"></v-img>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3" class="pt-6">
        <span>&copy; {{ new Date().getFullYear() }} - Prios Kompetanse -</span>
        <span>by</span>
        <a href="https://prios.no/" target="_blank" class="white--text"> Prios Software </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="3">
        <div>
          <span>E-mail coordinator: </span>
          <br/>
          <a class="white--text" href="mailto:post@prios.no"> post@prios.no </a>
        </div>
      </v-col>
    </v-row>
  </v-footer> 
</template>

<script>
  export default {
    data(){
      return {

      }
    }
  }
</script>