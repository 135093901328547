import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import NewsView from '../views/NewsView.vue'
import TrainingViews from '../views/TrainingModuleView.vue'
import OutputsView from '../views/OutputsView.vue'
import EvisitView from '../views/EvisitView.vue'
import EvisitTool from '../views/EvisitTool/EvisitToolCompanies.vue'
import EvisitToolCompany from '../views/EvisitTool/EvisitToolCompany.vue'
import EvisitToolCompanyAbout from '../views/EvisitTool/EvisitToolCompanyAbout.vue'
import EvisitToolCompanyContact from '../views/EvisitTool/EvisitToolCompanyContact.vue'



import EvisitToolCompanyDummy from '../views/EvisitTool/dummy/evisittoolCompanys.vue'
import EvisitToolAboutDummy from '../views/EvisitTool/dummy/evisittoolCompanyAbouts.vue'


import MappingTool from '../views/MappingTools.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/about', name: 'about', component: AboutView },
  { path: '/news', name: 'news', component: NewsView },
  { path: '/training', name: 'Training Module', component: TrainingViews },
  { path: '/outputs', name: 'Outputs', component: OutputsView },
  { path: '/evisit', name: 'Evisit', component: EvisitView },
  // E-visit tool
  { path: '/evisittool', name: 'Evisit Tool', component: EvisitTool },
  { path: '/evisittoolCompany', name: 'Evisit Tool Company', component: EvisitToolCompany },
  { path: '/evisittoolCompanyAbout', name: 'Evisit Tool Company About', component: EvisitToolCompanyAbout },
  { path: '/evisittoolCompanyContact', name: 'Evisit Tool Company Contact', component: EvisitToolCompanyContact },
  // Evisit Dummy
  { path: '/evisittoolCompanys', name: 'Evisit Tool Company Contacts', component: EvisitToolCompanyDummy },
  { path: '/evisittoolCompanyAbouts', name: 'Evisit Tool Company Abouts', component: EvisitToolAboutDummy },



  { path: '/mapping', name: 'Mapping Tool', component: MappingTool },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
