<template>
  <div>
    <v-tabs grow>

      <v-tab>Handbook digital readiness</v-tab>
      <v-tab>Teachers Digital Knowledge - Toolkit</v-tab>

      <v-tab-item class="pt-6 mt-5">
        <v-row class="pa-5">
          <v-col class="mb-10" cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(handbook, handbookIndex) in handbookCards" :key="handbookIndex">
                
            <a v-if="handbook.link !== ''" :href="handbook.link" target="_blank">
              <v-hover v-slot:default="{ hover }">
                <v-card height="100%" class="pa-2" :elevation="hover ? 12 : 2">
                  <v-img v-if="handbook.src !== ''" :src="handbook.image" width="300px" height="300px" contain style="margin: 0 auto;"></v-img>
                </v-card>
              </v-hover>
            </a>
            <v-hover v-slot:default="{ hover }" v-else>
              <v-card height="100%" class="pa-2" :elevation="hover ? 12 : 2">
                <v-img v-if="handbook.src !== ''" :src="handbook.image" width="300px" height="300px" contain style="margin: 0 auto;"></v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item class="pt-6 mt-5">
        <v-row class="pa-5">
          <v-col class="mb-10" cols="12" xl="4" lg="4" md="4" sm="12" xs="12" v-for="(teacher, teacherIndex) in teacherCards" :key="teacherIndex">
                
            <a v-if="teacher.link !== ''" :href="teacher.link" target="_blank">
              <v-hover v-slot:default="{ hover }">
                <v-card height="100%" class="pa-2" :elevation="hover ? 12 : 2">
                  <v-img v-if="teacher.src !== ''" :src="teacher.image" width="300px" height="300px" contain style="margin: 0 auto;"></v-img>
                </v-card>
              </v-hover>
            </a>
            <v-hover v-slot:default="{ hover }" v-else>
              <v-card height="100%" class="pa-2" :elevation="hover ? 12 : 2">
                <v-img v-if="teacher.src !== ''" :src="teacher.image" width="300px" height="300px" contain style="margin: 0 auto;"></v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-tab-item>

      </v-tabs>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        handbookCards: [
          {
            image: require('@/assets/HandbookEnglish.png'),
            link: 'https://anyflip.com/whhx/zyer/'
          },
          {
            image: require('@/assets/HandbookPortugal.png'),
            link: 'https://anyflip.com/whhx/wxwi/'
          },
          {
            image: require('@/assets/HandbookGermany.png'),
            link: 'https://anyflip.com/whhx/ahix/'
          },
          {
            image: require('@/assets/NorwegianHandbook.jpg'),
            link: 'https://anyflip.com/whhx/pqej/'
          },
          {
            image: require('@/assets/DanishHandbook.jpg'),
            link: 'https://anyflip.com/whhx/aocz/'
          },
        ],
        teacherCards: [
          {
            image: require('@/assets/Toolkitpicture.png'),
            link: 'https://anyflip.com/whhx/npmw/'
          },
        ],
      }
    }
  }
</script>