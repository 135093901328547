<template>
  <v-dialog v-model="partnerDialog" max-width="800px">
    <v-card tile class="pa-10">
      <v-btn absolute fab small top right class="mt-6 mr-2 elevation-0" @click="closeDialog()">
        <!-- <v-icon>clear</v-icon> -->
        X 
      </v-btn>
      <v-img v-if="item.src !== ''" :src="item.src" max-height="30vh" contain class="px-6 py-10"></v-img>
      <v-divider></v-divider>
      <v-card-text class="pt-2">
        <div class="title text-center">
          {{ item.name }}
        </div>
        <div class="subtitle-2 py-3">
          <div v-if="item.name == 'Prios'">
            <p>
              Prios Kompetanse AS (Prios) is a research - based corporation established by several cooperative competence environments.
              Prios as research center aims to implement the idea of lifelong learning, support and conduct development projects. 
              The center is open to all who wish to develop their talents, refresh their knowledge, improve their business, 
              needs new or better digital tools or have ideas in need for support to be fulfilled.
            </p>
            <p>
              Our courses and assignments are carried out by own staff or professionals from our large network.
              Prios receives no government funding. Main source of income is sales fees from our services. The main sectors we operate is: <br>
              - Adult and youth education. Formal and non - formal education, training and courses. <br>
              - Tutor and advisor for innovation processes for startups and well - established companies. <br>
              - Software development, tailored solutions for mainly education and SME. We developed and own the Follow - Up® concept
            </p>
            <p class="text-center">
              <v-btn :href="item.link" target="_blank" class="primary">Link to homepage</v-btn>
            </p>
          </div>
          <div v-if="item.name == 'VUC Storstrøm'">
            <p>
              VUC Storstrøm is an adult education centre with approximately 200 employees of which 160 are teachers. 
              We have about 5000 fulltime / part time students in 2019 with a turnover of approximately 18 M€. 
              VUC Storstrøm covers a region with about 270000 inhabitants in a rural area and is represented in six Danish towns.
            </p>
            <p>
              VUC Storstrøm offers general adult education, higher preparatory examination, preparatory adult education (basic skills) and several special programs for learners with special needs,
              as well as custom - tailored courses for companies. 
              Examination is mandatory in all subjects and on all levels giving formal competences. 
              Our students are often adult - meaning from the age of 18 or 25 depending on the course - "second chance" students or students creating a new career path for themselves, 
              so creating upskilling pathways is an integral part of our DNA.
            </p>
            <p class="text-center">
              <v-btn :href="item.link" target="_blank" class="primary">Link to homepage</v-btn>
            </p>
          </div>
          <div v-if="item.name == 'OSZ IMT'">
            <p>
              The OSZ IMT is the largest public IT College in Berlin with over 2600 students, both enrolled in fulltime as well as dual vocational training (1600 students). 
              It has a staff of 145 teachers and trainers and cooperates with 900 IT - companies in the larger Berlin area. 
              The OSZ IMT has been granted many awards and prices for its innovative training courses, particularly in the sector of networking but also in applied computing. 
              The OSZ IMT participates in university - based research on the impact of mobility programs and has conducted many empirical studies in cooperation with the Berlin Humboldt University.
            </p>
            <p class="text-center">
              <v-btn :href="item.link" target="_blank" class="primary">Link to homepage</v-btn>
            </p>
          </div>
          <div v-if="item.name == 'Val skoler'">
            <p>
              Val skoler is a private upper secondary school owned by a Christian organization, Norsk Luthersk Misjonssamband. 
              The school is located in Nærøysund municipality in Mid - Norway (county Trøndelag), with a population of approximately 10 000 people. 
              Nærøysund is a typical Norwegian coastal area, where the main trade branches are aquaculture and agriculture in addition to sales/service management. 
              Val skoler offers education inside the use of natural resources, more specific aquaculture, agriculture and horse management. 
              The school is also offering one academic year after the two years of vocational education which give the students university and college admissions certification. 
              Val hosts approximately 150 students, 16 - 19 year of age, and 55 staff members in total. 
              The students are from all over Norway, and almost everyone lives in dormitories inside the school area. 
              Val own and run their own operating departments both for agriculture and aquaculture activities. 
              Most of the students' practical training inside the study programs at Val takes place at our own farms and facilities, roughly two days a week. 
              In addition, the students have several weeks of work experience at other companies during the year.
            </p>
            <p class="text-center">
              <v-btn :href="item.link" target="_blank" class="primary">Link to homepage</v-btn>
            </p>
          </div>
          <div v-if="item.name == 'ENSINUS ETP'">
            <p>
              ENSINUS ETP is the holding that created INETE in 1989. INETE is a vocational school for young people undergoing initial training as well as continuous training of professionals. 
              The school is in the center of Lisbon, having access to a good public transport network. 
              Since the beginning INETE has trained more than 3000 highly qualified technicians. 
              This year, INETE offers 9 different courses Managemen, Accountancy, Optics, Computing, Electronics and Industrial Automation, Mechanics, Car Mechanics, Aircraft Maintenance and Juridical Services) attended by 659 students. 
              There are 74 teachers and 20 collaborators. 
              We have a very close relationship with the labor market and internationalization is a very important strategic goal. 
              We are also known for our innovative pedagogical approach. 
              The school was also chosen to exhibit its projects in the conference for the development of digital competences.
            </p>
            <p class="text-center">
              <v-btn :href="item.link" target="_blank" class="primary">Link to homepage</v-btn>
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      partnerDialog: false,
      item: {},
    }
  },
  methods: {
    openDialog(item){
      this.partnerDialog = true;
      this.item = item;
    },

    closeDialog(){
      this.partnerDialog = false;
    }
  }
}
</script>