<template>
  <v-container>
    <PartnerDialog ref="openingTheDialog"></PartnerDialog>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" v-for="(item, index) in partners" :key="index">
        <v-hover v-slot:default="{ hover }">
          <v-card height="100%" class="d-flex align-center pa-2" :elevation="hover ? 12 : 2" @click="$refs.openingTheDialog.openDialog(item)">
            <v-img :src="item.src" contain ></v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartnerDialog from "../components/partnerDialog.vue"
export default {
  components: {
    PartnerDialog
  },
  data(){
    return {
      partners: [
        {name: "Prios", src: "logos/prioslogo_PNG big_dark.png", link: "https://www.prios.no/"},
        {name: "VUC Storstrøm", src: "logos/vuc_storstrom.png", link: "https://www.vucstor.dk/"},
        {name: "OSZ IMT", src: "logos/Logo_OSZIMT.jpg", link: "https://www.oszimt.de/"},
        {name: "Val skoler", src: "logos/valvideregaende.jpg", link: "https://val.vgs.no/"},
        {name: "ENSINUS ETP", src: "logos/Logo_INETE-02.jpg", link: "https://www.inete.pt"}
      ]
    }
  }
}
</script>
