<template>
  <div>
    <EvisitCompanies></EvisitCompanies>
  </div>
</template>

<script>
import EvisitCompanies from "@/components/Evisit/EvisitCompanies.vue"
  export default {
    components: {
      EvisitCompanies
    },
    data(){
      return {

      }
    },
    methods: {

    }
  }
</script>